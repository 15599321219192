<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="setorizacao-nome"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-nome-setorizacao-bdgd"
            label="Nome da setorização"
            outlined
            :error-messages="errors"
            v-model="nome"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <div id="setorizacao-bdgd-versao">
      <v-row
        id="setorizacao-mes"
        class="mx-auto"
      >
        <v-col cols="12">
          <select-all-bdgd-versions
            label="Versão da BDGD"
            :companyId="companyId"
            @bdgdVersionSelected="bdgdVersionSelected"
          />
        </v-col>
      </v-row>
    </div>
    <v-row
      id="setorizacao-mapa"
      class="mx-auto"
    >
      <v-col cols="12">
        <mapa-area-concessao
          :month="data"
          :bdgdVersion="bdgdVersion"
          @aratFeaturesChanged="handleAratFeaturesChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  name: 'FirstTabContent',

  mixins: [dateFormatterMixins],

  components: {
    SelectAllBdgdVersions: () =>
      import('@/components/general/SelectAllBdgdVersions.vue'),
    MapaAreaConcessao: () =>
      import('@/components/setorizacoes/tabs/MapaAreaConcessao.vue')
  },

  props: {
    items: {
      type: Object
    }
  },

  data() {
    return {
      nome: null,
      mes: null,
      bdgdVersion: null,
      aratFeatures: 0
    };
  },

  mounted() {
    if (this.items) this.fillInputs();
  },

  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    },

    companyId() {
      return this.$store.getters.getCompanyId;
    }
  },

  watch: {
    nome() {
      this.emitEventDataUpdated();
    },

    mes() {
      this.emitEventDataUpdated();
    },

    bdgdVersion() {
      this.emitEventDataUpdated();
    },

    aratFeatures() {
      this.emitEventDataUpdated();
    }
  },

  methods: {
    bdgdVersionSelected(bdgdVersion) {
      this.mes = this.formatMonthToPicker(bdgdVersion.mes);
      this.bdgdVersion = bdgdVersion.versao;
    },

    handleAratFeaturesChanged(aratFeatures) {
      this.aratFeatures = aratFeatures;
    },

    emitEventDataUpdated() {
      this.$emit('data:updated', {
        nome: this.nome,
        mes: this.mes,
        data: this.data,
        bdgdVersion: this.bdgdVersion,
        aratFeatures: this.aratFeatures
      });
    },

    fillInputs() {
      this.nome = this.items.nome;
      this.mes = this.items.mes;
      this.bdgdVersion = this.items.bdgdVersion;
      this.aratFeatures = this.items.aratFeatures;
    }
  }
};
</script>
